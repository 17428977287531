"use client"
import React from "react"
import { useEffect, useState } from "react"
import { useTheme } from "@mui/material/styles"
import type { TextFieldProps } from "@mui/material"
import Icon from "@mdi/react"

import { TextField } from "../TextField"
import { mdiEye, mdiEyeOff } from "@mdi/js"
import { Button } from "../Button"

export interface PasswordFieldProps
  extends Partial<TextFieldProps<"outlined">> {
  showPassword?: boolean
  testId?: string
}
export default function PasswordField(props: PasswordFieldProps) {
  const theme = useTheme()
  const {
    testId = "",
    label = "",
    disabled = false,
    error = false,
    onChange,
    value = "",
    autoComplete = "off",
    ...restProps
  } = props ?? {}

  const [usePassword, setUsePassword] = useState<string>(value as string)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [useDisabled, setUseDisabled] = useState<boolean>(disabled)
  const [useError, setUseError] = useState<boolean>(error)

  useEffect(() => setUsePassword(value as string), [value])
  useEffect(() => setUseDisabled(disabled), [disabled])
  useEffect(() => setUseError(error), [error])

  return (
    <TextField
      {...restProps}
      value={usePassword}
      data-testid={testId}
      type={showPassword ? "text" : "password"}
      autoComplete={autoComplete}
      label={label}
      disabled={useDisabled}
      error={useError}
      onChange={onChange}
      InputProps={{
        ...(restProps.InputProps ?? {}),
        endAdornment: (
          <Button
            type="button"
            data-testid={`${testId}ShowPasswordToggle`}
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon
              color={theme.palette.text.secondary}
              size={theme.spacing(3)}
              path={showPassword ? mdiEyeOff : mdiEye}
            />
          </Button>
        ),
      }}
    />
  )
}
