"use client"

import type { FC } from "react"
import {
  AlertTitle as MuiAlertTitle,
  type AlertTitleProps,
} from "@mui/material"

export const AlertTitle: FC<AlertTitleProps> = (props) => (
  <MuiAlertTitle {...props} />
)
